/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
require("./src/styles/main.css")

exports.onClientEntry = () => {
  function usercentricsStyling() {        
		  const UCStyles = document.createElement("style")
          UCStyles.innerText = `[data-testid="uc-privacy-button"]{
	display: none;
}
[data-testid="uc-app-container"] > div > div > div > div > div > div > div > div > div > div {
	background-color: rgb(24 28 47) !important;
}
[data-testid="uc-app-container"] > div > div > div > div > div > div,
#uc-tab-list
{
	background-color: rgb(24 28 47) !important;
	border-bottom: 0px!important;
	border-top: 0px!important;
}
#uc-tab-list > button
{
	background-color: rgb(24 28 47) !important;
}
[data-testid="uc-footer"] div, [data-testid="uc-footer"] a, [data-testid="uc-footer"] button span, [data-testid="uc-container"]{
	color: white !important;
}
[data-testid="uc-tab-categories"], [data-testid="uc-tab-services"]{
	border-bottom: 2px solid rgb(189, 2, 58)!important;
	color: white !important;
	background: rgb(24 28 47) !important:
}
[data-testid="uc-heading-title"],
[data-testid="uc-message-container"],
[data-testid="uc-anchor-link"]{
	color: white !important;
}
[data-testid="uc-language-button"] > svg > g > g ,[data-testid="uc-close-button"] > svg > path {
	color: white !important;
	fill: white !important;
}
[data-testid="uc-deny-all-button"]{
	background-image: linear-gradient(to right, rgb(189, 2, 58), rgb(252, 69, 4));
}
[data-testid="uc-accept-all-button"]{
	background-image: linear-gradient(to right, rgb(189, 2, 58), rgb(252, 69, 4));
}
[data-testid="uc-save-button"]{
	color: rgb(48, 48, 48) !important;
}

[data-testid="uc-deny-all-button"]:hover{
	background-image: linear-gradient(to right, rgb(170, 0, 51), rgb(170, 0, 51));
}
[data-testid="uc-accept-all-button"]:hover{
	background-image: linear-gradient(to right, rgb(170, 0, 51), rgb(170, 0, 51));
}
button[aria-checked="true"] > svg > g > g > g > path:nth-child(1){
	fill:rgb(189, 2, 58);
}
[data-testid="uc-footer"] > div > div {
	justify-content: center;
}
`
		document
            .querySelector("#usercentrics-root")
            .shadowRoot.appendChild(UCStyles)
        }
var checkDiv = setInterval(function(){
if( window.UC_UI && window.UC_UI.isInitialized() && document.body.contains(document.querySelector("#usercentrics-root")) ) { 
	clearInterval(checkDiv);
	 	usercentricsStyling();
	}
}, 50); // check after 10ms every time
      }