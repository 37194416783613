exports.components = {
  "component---src-pages-404-de-js": () => import("./../../../src/pages/404.de.js" /* webpackChunkName: "component---src-pages-404-de-js" */),
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-templates-about-us-page-js": () => import("./../../../src/templates/about-us-page.js" /* webpackChunkName: "component---src-templates-about-us-page-js" */),
  "component---src-templates-approach-page-js": () => import("./../../../src/templates/approach-page.js" /* webpackChunkName: "component---src-templates-approach-page-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/category-page.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-impressum-page-js": () => import("./../../../src/templates/impressum-page.js" /* webpackChunkName: "component---src-templates-impressum-page-js" */),
  "component---src-templates-privacy-policy-page-js": () => import("./../../../src/templates/privacy-policy-page.js" /* webpackChunkName: "component---src-templates-privacy-policy-page-js" */),
  "component---src-templates-project-page-js": () => import("./../../../src/templates/project-page.js" /* webpackChunkName: "component---src-templates-project-page-js" */),
  "component---src-templates-projects-page-js": () => import("./../../../src/templates/projects-page.js" /* webpackChunkName: "component---src-templates-projects-page-js" */),
  "component---src-templates-services-page-js": () => import("./../../../src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */)
}

