module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NetConsultiv Agency","short_name":"NetConsultiv","start_url":"/","background_color":"#181c2f","display":"minimal-ui","icon":"src/images/netconsultiv-favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"115eb9a1bddc530df7d21b32638972a7"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","preconnect":["https://fonts.gstatic.com"],"custom":[{"name":"Gilroy","file":"/fonts/fonts.css"},{"name":"Roboto","file":"/fonts/fonts-roboto.css"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
